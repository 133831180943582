<template>
  <div v-if="isLoading">
    <p>Loading book details...</p>
  </div>
  <div v-else-if="book">
    <!-- Display fetched content -->
    <h2>{{ book.name }}</h2>
    <div class="content-box logs" v-html="log"></div>
    <div v-if="fetchedContent">
      <div class="share-button">
        <button v-if="fetchedBack" class="eread-button" @click="Previous">Previous</button>
        <!-- <button class="eread-button" @click="openAsContentHtml">Open in New Tab</button> -->
        <button class="eread-button" @click="reloadIframe">Reload</button>
        <button @click="fetchAndShareContent">Share</button>
        <button v-if="fetchedForward" class="eread-button" @click="Next">Next</button>
        <button v-else class="eread-button" @click="Updates">Updates</button>
      </div>
      <div v-if="iFrameLoading">
        <p>Loading chapter...</p>
      </div>
      <div v-else class="content-box iframe">
        <iframe :src="ChapterURL" width="100%" height="100%"></iframe>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import axios from "axios"; // Import Axios for making HTTP requests
import { useRoute } from "vue-router";
import bookStore from "@/stores/book-store";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";
import { firestore } from "@/firebaseConfig";
import userStore from "@/stores/user-store";

const Host = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL
  : "https://reader-pull.onrender.com";

const STATIC_HOST = "https://chapters-0d5o.onrender.com/pages/";

const route = useRoute();
const uid = computed(() => userStore.getters.getUser.uid);

// Define reactive variables to store user input and fetched content
const fetchedContent = ref("");
const fetchedBack = ref("");
const fetchedForward = ref("");
const id = ref(route.query.id);
const book = ref({});
const log = ref("<div class='log-info'>Loading...</div>");
const isLoading = ref(true);
const iFrameLoading = ref(true);

const ChapterURL = computed(
  () => STATIC_HOST + fetchedContent.value + `?t=${new Date().getTime()}`
);

// Method to handle iframe loading logic with timeout
const loadLater = async () => {
  // Wait for a short time before checking again
  await new Promise((resolve) => setTimeout(resolve, 30000));
  iFrameLoading.value = false;
};

// Call this method to start checking the URL
const loadIframe = () => {
  iFrameLoading.value = true;
  loadLater();
};

const reloadIframe = () => {
  iFrameLoading.value = true;
  fetchedContent.value = fetchedContent.value + `?t=${new Date().getTime()}`;
  iFrameLoading.value = false;
};

const fetchBookData = async () => {
  try {
    // Fetch all books if not already fetched
    if (!Object.keys(bookStore.getters.allBooks).length) {
      await bookStore.dispatch("fetchBooks", uid.value);
    }
    book.value = bookStore.getters.getBook(id.value);
    fetchChapter();
    if (!book.value) {
      console.error(`Book with ID ${id.value} not found.`);
    }
  } catch (error) {
    console.error("Error fetching books or book data:", error);
  } finally {
    isLoading.value = false;
  }
};

function getFormattedDateForFileName() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
}

// Call fetch logic on mount
onMounted(() => {
  fetchBookData();
});

const saveBook = (refresh) => {
  try {
    bookStore.dispatch("updateBook", { id: id.value, book: book.value });
    if (refresh) {
      fetchChapter();
    }
  } catch (error) {
    console.error("Could not updateBook " + error);
  }
};

const GetFullUrl = (value) => {
  const parsedUrl = new URL(book.value.url); // Adding https:// as a fallback
  const host = parsedUrl.hostname;
  return `${parsedUrl.protocol}//` + host + value;
};

const Next = () => {
  book.value.url = GetFullUrl(fetchedForward.value);
  saveBook(true);
  addLog("Getting next..", "log-info", false);
};

const Updates = async () => {
  addLog("Checking for any changes", "log-info", false);
  await fetchChapterFromServer(book.value.url, true);
  await GetNextChapter();
};

const Previous = () => {
  book.value.url = GetFullUrl(fetchedBack.value);
  saveBook(true);
  addLog("Getting previous..", "log-info", false);
};

const saveChapterToFirebase = async (chapterId, data) => {
  try {
    const chapterRef = doc(collection(firestore, "chapters"), chapterId);
    await setDoc(chapterRef, {
      htmlURL: data.url,
      backUrl: data.backUrl,
      forwardUrl: data.forwardUrl, // Set default role
    });
    //console.log(`Chapter ${chapterId} saved successfully.`);
  } catch (error) {
    console.error(`Error saving chapter ${chapterId}:`, error);
  }
};

const fetchChapterFromServer = async (chapterUrl, updateCurrent) => {
  var apiUrl = Host + "/fetch-rendered-text";
  addLog("Getting content from API..", "log-info");
  try {
    const response = await axios.post(apiUrl, {
      url: chapterUrl,
      selector: book.value.selector,
      back_selector: book.value.back_selector,
      forward_selector: book.value.forward_selector,
      filename:
        book.value.name.replaceAll(" ", "_") +
        getFormattedDateForFileName() +
        ".html",
    });
    addLog("API fetch completed.", "log-success");

    if (updateCurrent) {
      // Store the fetched text
      fetchedContent.value = response.data.url;
      fetchedBack.value = response.data.backUrl;
      if (fetchedForward.value !== response.data.forwardUrl) {
        fetchedForward.value = response.data.forwardUrl;
        updateLastRead();
        addLog("Updated.", "log-info");
      } else {
        addLog("No new update found", "log-info");
      }
    }

    await saveChapterToFirebase(chapterUrl.replaceAll("/", "_"), {
      url: response.data.url,
      backUrl: response.data.backUrl,
      forwardUrl: response.data.forwardUrl,
    });
    addLog("Values stored.", "log-info");
  } catch (error) {
    if (error.response) {
      addLog(
        `API Error ${error.response.status}: ${
          error.response.data.error || "Unknown error"
        }`,
        "log-error"
      );
    } else {
      addLog(`API Failed: ${error.message}`, "log-error");
    }
  }
};

const updateLastRead = () => {
  const timestamp = new Date();
  book.value.timestamp = timestamp.toLocaleString();
  saveBook(false);
};

const checkIfChapterExists = async (id) => {
  const chapterRef = doc(firestore, "chapters", id);
  const chapterSnapshot = await getDoc(chapterRef);
  return chapterSnapshot;
};

// Fetch text from the Express server
const fetchChapter = async () => {
  try {
    // Check if the chapter already exists in Firestore
    let chapterSnapshot = await checkIfChapterExists(
      book.value.url.replaceAll("/", "_")
    );

    if (chapterSnapshot.exists()) {
      // If the chapter exists, use the values from Firestore
      const chapterData = chapterSnapshot.data();
      fetchedContent.value = chapterData.htmlURL;
      fetchedBack.value = chapterData.backUrl;
      fetchedForward.value = chapterData.forwardUrl;
      addLog("Chapter found in Firestore, using existing data!", "log-success");

      updateLastRead();
      iFrameLoading.value = false;
    } else {
      // If the chapter doesn't exist, fetch from the API
      await fetchChapterFromServer(book.value.url, true);
      loadIframe();
    }

    //Preload Next Chapter, if it isn't loaded already
    await GetNextChapter();
  } catch (error) {
    addLog("Error fetching text:" + error, "log-error");
    fetchedContent.value = "Error fetching content.";
  }
};

const GetNextChapter = async () => {
  if (fetchedForward.value) {
    const nextUrl = GetFullUrl(fetchedForward.value);
    let chapterSnapshot = await checkIfChapterExists(
      nextUrl.replaceAll("/", "_")
    );
    addLog("<br/>", "log-info");

    if (!chapterSnapshot.exists()) {
      addLog("Preloading next chapter...", "log-info");
      fetchChapterFromServer(nextUrl, false);
    } else {
      addLog("Next chapter is ready!", "log-success");
    }
  } else {
    addLog("You're all caught up!", "log-success");
  }
};

const addLog = (message, className, append = true) => {
  const logEntry = document.createElement("div");
  logEntry.innerHTML = message;

  if (className) {
    logEntry.className = className;
  }
  // Auto-scroll to the bottom of the logs container
  const logsContainer = document.querySelector(".content-box.logs");
  if (append) {
    if (logsContainer) {
      logsContainer.appendChild(logEntry);

      logsContainer.scrollTop = logsContainer.scrollHeight;
    }
  } else {
    logsContainer.innerHTML = ""; // Clear existing logs
    logsContainer.appendChild(logEntry); // Add the new log entry
  }
};

/* const openAsContentHtml = () => {
  if (!fetchedContent.value) return;

  try {
    window.open(ChapterURL.value, "_blank");
  } catch (error) {
    console.error("Error saving content to server:", error);
  }
}; */

const fetchAndShareContent = async () => {
  try {
    // Use Web Share API to share the content
    if (navigator.share) {
      await navigator.share({
        url: ChapterURL.value,
      });
      //console.log("Content shared successfully!");
    } else {
      alert("Web Share API is not supported in this browser.");
    }
  } catch (error) {
    console.error("Error fetching or sharing iframe content:", error);
  }
};
</script>
<style scoped lang="scss">
.eread-button {
  margin: 5px;
}
.content-box {
  text-align: left;
  background-color: white;
  word-wrap: break-word; /* Enforce word wrapping */
  border: 1px solid #ccc; /* Optional: Add a border to define the box */
  padding: 10px; /* Optional: Add some padding inside the box */
}

.share-button {
  margin: 5px;
}

.logs {
  overflow: auto; /* Enable scrollbars when content overflows */
  height: 100px; /* Set fixed height */
}

.iframe {
  height: 200px; /* Set fixed height */
}
</style>
