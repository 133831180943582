import { createRouter, createWebHistory } from "vue-router";
import Login from "@/pages/Login.vue";
import Home from "@/pages/Home.vue";
import Code from "@/pages/Code.vue";
import eReader from "@/pages/eReader.vue";
import Book from "@/pages/Book.vue";
import Schedule from "@/pages/Schedule.vue";
import store from "@/stores/user-store";
import { auth } from "@/firebaseConfig"; // Use the shared auth instance
import { onAuthStateChanged } from "firebase/auth";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home",
    redirect: "/",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresAuth: false }, // This route does not require authentication
  },
  {
    path: "/schedule",
    name: "schedule",
    component: Schedule,
    meta: { requiresAuth: false }, // This route does not require authentication
  },
  {
    path: "/code",
    name: "code",
    component: Code,
    meta: { requiresAuth: true },
  },
  {
    path: "/ereader",
    name: "ereader",
    component: eReader,
    meta: { requiresAuth: true },
  },
  {
    path: "/book",
    name: "Book",
    component: Book,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let isAuthenticating = true; // A flag to track if authentication check is ongoing

// Monitor authentication state and initialize store
const authPromise = new Promise((resolve) => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      await store.dispatch("setAuthenticated", true);
      await store.dispatch("setUser", user);
      await store.dispatch("setRole");
    } else {
      await store.dispatch("setAuthenticated", false);
      await store.dispatch("setUser", null);
    }
    isAuthenticating = false;
    resolve();
  });
});

// Navigation guard to protect routes
router.beforeEach(async (to, from, next) => {
  // Wait for the authentication state check to complete
  if (isAuthenticating) {
    await authPromise;
  }

  const isAuthenticated = store.getters.isAuthenticated;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Route requires authentication
    if (!isAuthenticated) {
      return next({ name: "login" }); // Redirect to login if not authenticated
    }
  } else if (to.name === "login" && isAuthenticated) {
    // Prevent logged-in users from accessing login page
    return next({ name: "home" });
  }

  next(); // Allow navigation
});

export default router;
