<template>
  <h1>{{ new Date().toLocaleDateString("en-US", { year: "numeric", month: "long" }) }}</h1>
  <div class="schedule">
    <div
      v-for="(day, index) in days"
      :key="index"
      :class="['day', { 'highlight': index === currentDay }]"
    >
      <p>{{ day.name }}</p>
      <p :class="['date', { 'highlight': index === currentDay }]">{{ formattedDate(index) }}</p>
      <img :src="day.image" :alt="day.name" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      days: [
        { name: "Monday", image: "../img/school.png" },
        { name: "Tuesday", image: "../img/school.png" },
        { name: "Wednesday", image: "../img/school.png" },
        { name: "Thursday", image: "../img/school.png" },
        { name: "Friday", image: "../img/school.png" },
        { name: "Saturday", image: "../img/home.png" },
        { name: "Sunday", image: "../img/home.png" },
      ],

      today: new Date(),
      currentDay: new Date().getDay() === 0 ? 6 : new Date().getDay() - 1,
    };
  },
  methods: {
    formattedDate(index) {
      // Get today's date
      const today = new Date();

      // Calculate the date for the given index
      const targetDate = new Date();
      const dayDifference = index - this.currentDay;
      targetDate.setDate(today.getDate() + dayDifference);

      // Format date (e.g., "February 3")
      return targetDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.schedule {
  margin-top: 20px;
  display: flex;
  align-items: left;
  justify-content: center;
  gap: 2px;
  flex-wrap: wrap; // Ensures wrapping on smaller screens
}
.day {
  text-align: center;
  padding: 5px;
  border: 1px solid $text-green;
  font-weight: 600;
  color: $text-green;
}
.date {
  color: $text-green;
  font-size: 14px;
}
.day img {
  width: 100px;
  height: 55px;
}
.highlight {
  background-color: green;
  color: white;
  font-weight: 700;
}
</style>
